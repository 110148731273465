import React from "react";
import {StaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import "font-awesome/scss/font-awesome.scss";
import Nav from "components/Navi";
import Footer from "components/Footer";
// import CallSticky from "components/CallSticky"
import config from "../../data/SiteConfig";
import "scss/gatstrap.scss";
import '@fortawesome/fontawesome-svg-core/styles.css';

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div className="body">
        <Nav title={config.siteDescription.title} {...this.props} />
        <Helmet>
          <html lang="vi" amp />
          <meta name="description" content={config.siteDescription} />
        </Helmet>
        
        {children}
        <StaticQuery
      query={graphql`
      query footerData {
        footerData: allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "home-page" } } }
        ) {
          edges {
            node {
              frontmatter {
                
                socialLinks {
                  id
                  socialType
                  linkSocialURL
                  socialContent
                }
                
              }
            }
          }
        }
      }
      `}
      render={data => <Footer footerData={data.footerData.edges[0].node} />}
    />
        {/* <CallSticky/> */}
      </div>
    );
  }
}
