import { graphql, Link } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import CustomLink from 'components/CustomLink';
import CallSticky from 'components/CallSticky';
import { faYoutube, faFacebook, faTwitterSquare, faPinterest, faInstagramSquare} from '@fortawesome/free-brands-svg-icons';
import './style.scss';
class Footer extends React.Component {
  render() {
    const { frontmatter: socialLinks } = this.props.footerData;
    const socialList = [];
    if (socialLinks.socialLinks.length > 0) {
      socialLinks.socialLinks.forEach((social) => {
        socialList.push({
          socialType: social.socialType,
          linkSocialURL: social.linkSocialURL,
          socialContent: social.socialContent,
        });
      });
    }

    const SocialLinks =
      socialList.length > 0 ? (
        socialList.map((item, i = 0) => {
          ++i;
          let iconName;
          switch (item.socialType) {
            case 'youtube':
              iconName = faYoutube;
              break;
            case 'facebook':
              iconName = faFacebook;
              break;
            case 'twitter':
              iconName = faTwitterSquare;
              break;
            case 'pinterest':
              iconName = faPinterest;
              break;
            case 'intagram':
              iconName = faInstagramSquare;
              break;
            default:
              break;
          }
          return (
            <li className="media" key={i}>
              <div className="icon-styled">
                <FontAwesomeIcon
                  icon={iconName}
                />
              </div>
              <div className="media-body text-break">
                <CustomLink to={item.linkSocialURL} linkType="_blank">
                  {item.socialContent}
                </CustomLink>
              </div>
            </li>
          );
        })
      ) : (
        <p>ko co du lieu</p>
        // <React.Fragment />
      );
    return (
      <div className="container-fluid bg-primary footer" id="footer">
        <div className="container">
          <div className="row pt-5 pb-5">
            {/* Col 1 */}
            <div className="col-sm-12 col-md-12 col-lg-5 word-wrap">
              <h5>Thông tin liên hệ</h5>
              <hr />
              <ul className="footer-list-style">
                <li className="media">
                  <div className="icon-styled">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <div className="media-body">0903.531.777</div>
                </li>
                <li className="media">
                  <div className="icon-styled">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <div className="media-body text-break">
                    minhnguyet@hainammedia.com
                  </div>
                </li>
                <li className="media">
                  <div className="icon-styled">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                  </div>
                  <div className="media-body">
                    Căn Số 19, Đường Số 9, Khu Đô Thị Quốc Tế Mới Đa Phước, Phường Thanh Bình, Quận Hải Châu, Thành Phố Đà Nẵng.
                  </div>
                </li>
              </ul>
            </div>
            {/* Col 2 */}
            {/* <div className="col-sm-6 col-md-6 col-lg-2">
              <h5>Trang chủ</h5>
              <hr />
              <ul className="footer-list-style pl-2">
                <li>
                  <Link to="/">Giới thiệu</Link>
                </li>
                <li>
                  <Link to="/product/">Sản phẩm</Link>
                </li>
                <li>
                  <Link to="#service">Dịch vụ</Link>
                </li>
                <li>
                  <Link to="/pricing/">Bảng giá</Link>
                </li>
                <li>
                  <Link to="/#pricing">Liên hệ báo giá</Link>
                </li>
              </ul>
            </div> */}
            {/* Col 4 */}
            <div className="col-sm-12 col-md-12 col-lg-5">
              <h5>Socials</h5>
              <hr />
              <ul className="footer-list-style">{SocialLinks}</ul>
            </div>

            {/* Col 3 */}
            <div className="col-sm-12 col-md-12 col-lg-2 hn-pl-25">
              <h5>Khác</h5>
              <hr />
              <ul className="footer-list-style pl-2">
                <li>
                  <Link to="/hainamer/">Hải Namer</Link>
                </li>
                <li>
                  <Link to="/tags/tuyển-dụng">Tuyển dụng</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row bg-primary footer-line text-footer-line">
          <div className="col ">
            <p className="float-md-left">
              Bản quyền <span className="text-white">Hải Nam Media</span> 2019
            </p>
          </div>
        </div>
        {/* <CallSticky/> */}
      </div>
    );
  }
}

export default Footer;
