import { Link } from 'gatsby';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import CustomLink from 'components/CustomLink';
// import { Collapse } from 'reactstrap';
import './style.scss';

const CallSticky = ({ author, title }) => {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggle = () => setIsOpen(!isOpen);
  return (
    <div className="fixed-bottom clearfix mb-3 mr-3">
      <CustomLink
        to="tel:+84903531777"
        className="float-right bg-white py-2 border border-radius-1"
        linkType="external_noblank"
        // onMouseEnter={toggle} onMouseLeave={toggle}
      >
        {/* <Collapse isOpen={isOpen}> */}
          <div className="d-inline p-2 text-secondary">
            <span className="">0903.531.777</span>
          </div>
        {/* </Collapse> */}

        <CustomLink
          className="d-inline p-2 bg-secondary rounded-circle border-1rem"
          to="tel:+84903531777"
          linkType="external_noblank"
        >
          {/* <div className="d-inline bg-secondary  p-1"> */}
          <FontAwesomeIcon className="rounded-circle fa-lg" icon={faPhone} />
          {/* </div> */}
        </CustomLink>
      </CustomLink>
    </div>
  );
};


export default CallSticky;
